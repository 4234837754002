import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fbdate'
})
export class FbDatePipe implements PipeTransform {

  transform(value: any): Date {
    return new Date(value.seconds * 1000);
  }

}
