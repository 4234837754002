import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';
import 'moment-timezone';

@Pipe({
  name: 'dateInZone'
})
export class DateInZonePipe implements PipeTransform {

  transform(date: Date, format: string, zoneName: string): unknown {
    const m = moment.tz(date, zoneName);
    return m.format(format);
  }

}
