import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';



const routes: Routes = [
  { path: '', redirectTo: 'win', pathMatch: 'full' },
  { path: 'property', loadChildren: () => import('src/app/property/property.module').then(m => m.PropertyModule )},
  { path: 'win', loadChildren: () => import('src/app/win/win.module').then(m => m.WinModule) },
  { path: 'account', loadChildren: () => import('src/app/account/account.module').then(m => m.AccountModule)},
  { path: 'mynet', loadChildren: () => import('src/app/mynet/mynet.module').then(m => m.MynetModule)},
  { path: 'transaction', loadChildren: () => import('src/app/transaction/transaction.module').then(m => m.TransactionModule )},
  { path: 'report/pnl', loadChildren: () => import('src/app/report/pnl/pnl.module').then(m => m.PnlModule )},
  { path: 'admin', loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule )},
  { path: 'cash-flow', loadChildren: () => import('src/app/cash-flow/cash-flow.module').then(m => m.CashFlowModule )},
  { path: 'savings', loadChildren: () => import('src/app/savings/savings.module').then(m => m.SavingsModule )},
  { path: 'reservations', loadChildren: () => import('src/app/reservations/reservations.module').then(m => m.ReservationsModule )},


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
