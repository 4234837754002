import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import {AuthService} from "./login/auth.service";

import { initializeApp } from 'firebase/app';

declare const jQuery: any;

// import * as firebase from "firebase/app";
// import 'firebase/auth';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


import {User} from "./login/user.model";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  user: any;

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) {
    this.authService.user.subscribe(user => this.user = user);
  }

  ngOnInit(): void {

    this.initializeAuth();
    this.intializeSidebar();

  }

  initializeAuth() {

    firebase.auth().onAuthStateChanged(r => {
      // console.log('onAuthStateChanged');
      // console.log(r);
      // console.log(firebase.auth().currentUser);
      this.doAuthStateChanged(r);
    });

  }

  doAuthStateChanged(r) {

    if(r == null) {
      this.authService.user.next(null);
      return;
    }

    const user = new User(r.email, r.displayName, r.uid);
    this.authService.user.next(user);

    // this.authService.intializeOwner(user);

  }

  intializeSidebar() {

    jQuery(document).ready(function () {

      jQuery('#sidebarCollapse').on('click', function () {
        jQuery('#sidebar').toggleClass('active');
      });

    });


  }




}
