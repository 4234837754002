import { Injectable } from '@angular/core';

import {Observable, ReplaySubject } from 'rxjs';
// import {AngularFirestore} from "@angular/fire/firestore";

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

import {User} from "./user.model";

// import * as firebase from "firebase/app";
// import "firebase/auth";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static USER_KEY: string = 'user';

  user = new ReplaySubject<User>();

  constructor(private afs: AngularFirestore) { }

  initialize() {

    const userString = localStorage.getItem(AuthService.USER_KEY);
    if(userString) {
      const _user = JSON.parse(userString);
      const user = new User(_user.email, _user.name, _user.uid);
      this.user.next(user);
    }

    this.user.subscribe(user => {
        if(user == null) {
          localStorage.removeItem(AuthService.USER_KEY);
        } else {
          const _userString = JSON.stringify(user);
          localStorage.setItem(AuthService.USER_KEY, _userString);
        }
    });

  }

  /*
  intializeOwner(user: User) {

    const path = user.path();

    const docRef = this.afs.doc(path);

    docRef.ref.get().then(doc => {

      console.log(doc.exists);

      if(doc.exists == false) {

        console.log('creating owner');
        docRef.set(user);
      }

    });

  }

   */

  signOut() {

    this.user.next(null);

    firebase.auth().signOut();


  }


}
