import { Component, OnInit } from '@angular/core';


import { auth } from 'firebaseui'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private auth: AngularFireAuth) { }

  ngOnInit(): void {
    this.initializeFirebase9();
  }

  initializeFirebase9() {

    var uiConfig = {
      signInFlow: 'popup',
      // signInSuccessUrl: '/win',
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID
      ],
      tosUrl: '/tos',
      // Privacy policy url/callback.
      privacyPolicyUrl: function() {
        window.location.assign('/privacy');
      },
    };


    const _auth = firebase.auth();
    const ui = new auth.AuthUI(_auth);
    ui.start('#firebaseui-auth-container', uiConfig);


  }



}
