
<div *ngIf="user == null">

  <app-login></app-login>

</div>


<div *ngIf="user" class="wrapper">

  <!-- Sidebar -->
  <app-side-navbar></app-side-navbar>

  <!-- Page Content -->
  <div id="content">

    <nav class="mb-3">
      <button type="button" id="sidebarCollapse" class="btn btn-secondary">
        <i class="fas fa-align-left"></i>
        <span></span>
      </button>
    </nav>


    <router-outlet></router-outlet>

  </div>

</div>
