
export class User {

  public isAdmin = false;

  constructor(public email: string, public name: string, public uid: string) {
    this.isAdmin = (uid == 'HLwtMMyMeiZcfXWjumP677KVIB63');
  }

  path(child?: string) : string {
    if(child) {
      return `/owner/${this.uid}${child}`;
    } else {
      return `/owner/${this.uid}`;
    }
  }


}
