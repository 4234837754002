import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// import { AngularFireModule } from '@angular/fire';
// import {AngularFirestore, AngularFirestoreModule, SETTINGS} from '@angular/fire/firestore';

// import { FirestoreSettingsToken } from '@angular/fire/firestore';

// import { FirestoreSettingsToken } from '@firebase/firestore'

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';

import {getAuth, provideAuth} from '@angular/fire/auth';

import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { FIREBASE_OPTIONS } from '@angular/fire/compat';



import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavbarComponent } from './shared/side-navbar.component';
import {SharedModule} from "./shared/shared.module";
import {LoginComponent} from "./login/login.component";
import {AuthService} from "./login/auth.service";


@NgModule({
  declarations: [
    AppComponent,
    SideNavbarComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth() )
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase }
    // { provide: SETTINGS, useValue: environment.production ? null : { host: 'localhost:8080', ssl: false } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private afs: AngularFirestore,
              private authService: AuthService) {


    this.authService.initialize();


  }

}
