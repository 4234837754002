import { Component, OnInit } from '@angular/core';
import {AuthService} from "../login/auth.service";
import {User} from "../login/user.model";

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit {

  user: User;

  constructor(private authService: AuthService) {
    this.authService.user.subscribe(user => this.user = user);
  }

  ngOnInit(): void {
  }

  signOut() {

    this.authService.signOut();

  }

}
