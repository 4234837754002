<nav id="sidebar">

  <div class="sidebar-header">
    <h3>IBP</h3>
  </div>

  <ul class="list-unstyled components">
<!--    <p>Dummy Heading</p>-->


    <li>
      <a href="/win">Win</a>
    </li>
    <li>
      <a href="/task">Tasks</a>
    </li>
    <li>
      <a href="/trips">Trips</a>
    </li>
    <li>
      <a href="/mynet">My Net</a>
    </li>
    <li>
      <a href="/cash-flow">Cash Flow</a>
    </li>
    <li>
      <a href="/savings/index">Savings</a>
    </li>
    <li>
      <a href="/reservations">Reservations</a>
    </li>
    <li>
      <a href="/reservations/cleaning">Cleaning</a>
    </li>

    <li>
      <a href="/receipts">Receipts</a>
    </li>


    <li class="active">
      <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Reports</a>
      <ul class="collapse list-unstyled" id="homeSubmenu">
        <li>
          <a href="/mynet/growth">My Net Growth</a>
        </li>
        <li>
          <a href="/report/pnl">P & L</a>
        </li>
        <li>
          <a href="#">Expenses</a>
        </li>
        <li>
          <a href="#">Revenue</a>
        </li>
      </ul>
    </li>

    <li>
      <a href="/transaction/list">Transactions</a>
    </li>


    <li>
      <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Settings</a>
      <ul class="collapse list-unstyled" id="pageSubmenu">
        <li>
          <a (click)="signOut();">Sign Out</a>
        </li>
        <li>
          <a href="/property/list">Properties</a>
        </li>
        <li>
          <a href="/account/list">Accounts</a>
        </li>
        <li *ngIf="user?.isAdmin">
          <a href="/admin/settings">Admin</a>
        </li>

      </ul>
    </li>


<!--
    <li>
      <a href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">Pages</a>
      <ul class="collapse list-unstyled" id="pageSubmenu">
        <li>
          <a href="#">Page 1</a>
        </li>
        <li>
          <a href="#">Page 2</a>
        </li>
        <li>
          <a href="#">Page 3</a>
        </li>
      </ul>
    </li>
-->

  </ul>

</nav>
