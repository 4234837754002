import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InfoMessageComponent} from "./info-message.component";

import {HttpClientModule} from '@angular/common/http';
import { FbDatePipe } from './fb-date.pipe';
import { BooleanStatusComponent } from './boolean-status.component';
import {DateInZonePipe} from "./date-in-zone.pipe";



@NgModule({
  declarations: [InfoMessageComponent, FbDatePipe, BooleanStatusComponent, DateInZonePipe ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule

  ],
  exports: [InfoMessageComponent,
            FormsModule,
            ReactiveFormsModule,
            HttpClientModule,
            FbDatePipe,
            BooleanStatusComponent,
            DateInZonePipe
          ]
})
export class SharedModule { }
