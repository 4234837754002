// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const db = "http://localhost:8080";
const db = "https://ironbay-7a7be.firebaseio.com";

console.log('fb is.. ' + db);

export const environment = {
  production: false,
  db: db,
  firebase: {
    apiKey: "AIzaSyDDI81SCMp4SU-TLHXlFISQ7rCG3CyqlwE",
    authDomain: "ironbay-7a7be.firebaseapp.com",
    databaseURL: db,
    projectId: "ironbay-7a7be",
    storageBucket: "ironbay-7a7be.appspot.com",
    messagingSenderId: "432193654188",
    appId: "1:432193654188:web:cc895ebba4e561b6593e09",
    measurementId: "G-MYB9J6YEDQ"
  }
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
